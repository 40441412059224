import {
    downloadPdcAttachmentSaga,
    fetchInspectionsSaga,
    fetchMoreInspectionsSaga,
    loadPdcInformationSaga,
    loadPdfSaga,
} from './saga/PDCSaga';
import { downloadProductActivationsSaga } from './saga/ProductActivationSaga';
import { trackPDFDownload } from './saga/AnalyticsSaga';
import { loadObjectTreeSaga, filterPDCBySelectedDrivers } from './saga/ObjectTreeSaga';

export const combinedSagas = [
    downloadPdcAttachmentSaga,
    fetchInspectionsSaga,
    fetchMoreInspectionsSaga,
    loadPdcInformationSaga,
    downloadProductActivationsSaga,
    loadPdfSaga,
    trackPDFDownload,
    loadObjectTreeSaga,
    filterPDCBySelectedDrivers,
];

export function runSagas(middleware) {
    combinedSagas.forEach((saga) => {
        middleware.run(saga);
    });
}
