import React from 'react';
import PropTypes from 'prop-types';

import ChangelogEntry from './ChangelogEntry';

const ChangelogBody = ({ entries }) => (
    <div>
        {entries.map((entry) => (
            <ChangelogEntry key={entry.releaseNumber} entry={entry} />
        ))}
    </div>
);

ChangelogBody.propTypes = {
    entries: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string.isRequired,
            messages: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string.isRequired,
                    imageUrl: PropTypes.string,
                }),
            ).isRequired,

            releaseNumber: PropTypes.number.isRequired,
        }),
    ).isRequired,
};

export default ChangelogBody;
