import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppRouter from '../AppRouter';

import handleWhiteScreenOfDeath from './handleWhiteScreenOfDeath';

const renderApplication = (
    id,
    reduxStore,
    translations,
    injectedDocument,
    injectedHandleWhiteScreenOfDeath = handleWhiteScreenOfDeath,
) => {
    try {
        const container = injectedDocument.getElementById(id);
        const root = createRoot(container);

        root.render(
            <Provider store={reduxStore}>
                <AppRouter translations={translations} />
            </Provider>,
        );
    } catch (error) {
        injectedHandleWhiteScreenOfDeath(error, id);
    }
};

export default renderApplication;
