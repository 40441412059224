import { createSelector } from 'reselect';

import { VEHICLE_CONDITION_FILTER } from './constants';

export const getUIState = (state) => state.uiState;
export const getFilters = (state) => state.filters;
export const getExperimentalFeatures = (state) => getUIState(state).experimentalFeatures;
export const getInspections = (state) => state.pdc;
export const hasInspections = (state) => Boolean(state.pdc.items.length);
export const getSelectedInspection = (state) => getInspections(state).selectedInspection;
export const getPdcSidebar = (state) => getInspections(state).sidebar || {};
export const getPdcAttachments = (state) => getInspections(state).pdcAttachments || {};
export const getPDCDownloads = (state) => getInspections(state).pdcDownloads;
export const getActivationStates = (state) => state.productActivation.activationStates;
export const getVehicleConditionFilter = (state) =>
    getFilters(state).filter((filter) => filter.filterType === VEHICLE_CONDITION_FILTER);
export const getPDCIsLoading = (state) => state.pdc.isLoading;
export const getDrivers = (state) => state.pdc.drivers || [];

export const getShowChangelog = (state) => state.changelog.show;
export const getShowChangelogFilterLevel = (state) => state.changelog.filterLevel;

export const isManServiceCareActivatedForSelectedInspection = createSelector(
    getActivationStates,
    getSelectedInspection,
    (activationStates, selectedInspection) =>
        activationStates.some(
            (activationState) =>
                activationState.asset_id === selectedInspection.assetId &&
                (activationState.booked_products || []).length > 0,
        ),
);
