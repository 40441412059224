/* eslint-disable max-lines-per-function */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getSelectedInspection, isManServiceCareActivatedForSelectedInspection } from '../../selectors';

import getServiceLocation from '../../lib/getServiceLocation';
import optional from '../common/Optional';

import AttachmentContainer from './AttachmentContainer';

import { BOOK_SERVICE_CARE_PATH } from '../../constants';

const BaseComponent = (props) => {
    const { pdc = {}, isManServiceCareActivated } = props;
    const { damages } = pdc;

    return (
        <div className={'row'} data-testid={'damages-detail-container'}>
            {damages.map((damage) => (
                <div key={`${damage._resource.id}`} className={'margin-bottom-15 damage-row'}>
                    <div className={'margin-bottom-10'}>
                        <div className={'text-size-16 text-bold'}>{damage.description || '-'}</div>
                    </div>

                    <div className={'margin-bottom-10'}>
                        <div className={'text-size-small text-color-gray'}>
                            <FormattedMessage id={'report.pdc.damage.description'} />
                        </div>
                        <div>
                            {damage.alert_level === 'WARNING' ? (
                                <i className={'rioglyph rioglyph-warning-sign text-color-danger'} />
                            ) : (
                                ''
                            )}
                            {damage.classifications.map((classification) => classification.description).join(', ')}
                        </div>
                    </div>

                    {optional(
                        damage.user_notes,
                        <div className={'margin-bottom-10'}>
                            <div className={'text-size-small text-color-gray'}>
                                <FormattedMessage id={'report.pdc.damage.descriptionNotes'} />
                            </div>
                            <div>{damage.user_notes}</div>
                        </div>,
                    )}

                    {optional(
                        damage.attachments.length,
                        <div className={'margin-bottom-10'}>
                            <div className={'text-size-small text-color-gray'}>
                                <FormattedMessage id={'report.pdc.damage.attachments'} />
                            </div>
                            <div className={'attachments-container display-flex'}>
                                {damage.attachments.map((attachment) => (
                                    <AttachmentContainer
                                        key={attachment._link.href}
                                        resourceUrl={attachment._link.href}
                                    />
                                ))}
                            </div>
                        </div>,
                    )}

                    {optional(
                        isManServiceCareActivated,
                        <a
                            href={`${getServiceLocation('damageReport')}/${damage._resource.id}`}
                            target={'_blank'}
                            className={'btn btn-primary btn-outline'}
                            rel="noreferrer"
                        >
                            <FormattedMessage id={'servicecare.link'} />
                        </a>,
                    )}
                </div>
            ))}

            {optional(
                !isManServiceCareActivated,
                <div>
                    <div className={'height-100'} />
                    <div
                        className={
                            'border-bottom-0 border-left-0 border-right-0 border-style-solid border-width-1 ' +
                            'border-color-lighter position-absolute bg-white bottom-25 left-20 right-20 ' +
                            'margin-bottom-25 padding-bottom-20'
                        }
                    >
                        <strong>
                            <p>
                                <FormattedMessage id={'servicecare.notactive'} />
                            </p>
                        </strong>
                        <a
                            href={`${getServiceLocation('marketplace')}${BOOK_SERVICE_CARE_PATH}`}
                            target={'_blank'}
                            className={'margin-bottom-10'}
                            rel="noreferrer"
                        >
                            <span className={'rioglyph rioglyph-angle-double-right'}>&nbsp;</span>
                            <span className={'rioglyph rioglyph-shopping-cart'}>&nbsp;</span>
                            <b>
                                <FormattedMessage id={'servicecare.gotomarketplace'} />
                                &nbsp;
                            </b>
                            <FormattedMessage id={'servicecare.marketplace.link'} />
                        </a>
                    </div>
                </div>,
            )}
        </div>
    );
};

BaseComponent.propTypes = {
    pdc: PropTypes.object.isRequired,
    isManServiceCareActivated: PropTypes.bool.isRequired,
};

export const mapStateToProps = (state) => ({
    pdc: getSelectedInspection(state),
    isManServiceCareActivated: isManServiceCareActivatedForSelectedInspection(state),
});

export { BaseComponent };
export default injectIntl(connect(mapStateToProps)(BaseComponent));
