import _ from 'lodash';

const camelCasify = (input) => {
    if (!_.isObject(input)) {
        return input;
    } else if (_.isArray(input)) {
        return _.map(input, camelCasify);
    }

    const result = {};
    _.each(input, (value, key) => {
        result[_.camelCase(key)] = camelCasify(value);
    });

    return result;
};

export default camelCasify;
