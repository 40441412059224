import { STORE_PRODUCT_ACTIVATIONS } from '../constants';

const getInitialState = () => {
    return {
        activationStates: [],
    };
};

const ProductActivationReducer = (state = getInitialState(), action = {}) => {
    if (action.type === STORE_PRODUCT_ACTIVATIONS) {
        const { activationStates } = action.payload;
        return {
            ...state,
            activationStates,
        };
    }

    return state;
};

export default ProductActivationReducer;
