const attachFetchErrorListener = (document, root, url) => {
    document.addEventListener('FetchResponseError', (ev) => {
        const Unauthorized = 401;
        if (ev.detail && ev.detail.status === Unauthorized) {
            root.location.replace(url);
        }
    });
};

export default attachFetchErrorListener;
