import { SHOW_CHANGELOG, HIDE_CHANGELOG, CHANGELOG_FILTER_NEW, CHANGELOG_FILTER_ALL } from '../constants';
import { hasNewVersion } from '../components/Changelog/ChangelogStaticContent';

const initialState = {
    show: hasNewVersion(),
    filterLevel: CHANGELOG_FILTER_NEW,
};

const ChangelogReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case SHOW_CHANGELOG:
            return {
                ...state,
                show: true,
                filterLevel: CHANGELOG_FILTER_ALL,
            };
        case HIDE_CHANGELOG:
            return {
                ...state,
                show: false,
            };
        default:
            return state;
    }
};

export default ChangelogReducer;
