import {
    HIDE_SESSION_EXPIRED_DIALOG,
    PDC_EXPORT_PDF,
    STORE_PDC_INSPECTIONS,
    PDC_FETCH_MORE_INSPECTIONS,
    PDC_SELECTED,
    STORE_VEHICLES,
    STORE_SELECTED_PDC,
    SHOW_PDC_SIDEBAR,
    DOWNLOAD_PDC_ATTACHMENT,
    STORE_PDC_ATTACHMENT,
    PDC_REMOVE_FILTER,
    PDC_SET_FILTER,
    PDF_DOWNLOAD_FINISHED,
    PDF_DOWNLOAD_PENDING,
    DOWNLOAD_PRODUCT_ACTIVATIONS,
    STORE_PRODUCT_ACTIVATIONS,
    PDC_FETCH_INSPECTIONS,
    STORE_DRIVERS,
    FILTER_PDC_BY_SELECTED_DRIVERS,
} from '../constants';

export function hideSessionExpiredDialog() {
    return {
        type: HIDE_SESSION_EXPIRED_DIALOG,
    };
}

export function storePdcInspections(pdcResource) {
    return {
        type: STORE_PDC_INSPECTIONS,
        payload: pdcResource,
    };
}

export function storeVehiclesAction(vehicles) {
    return {
        type: STORE_VEHICLES,
        payload: vehicles,
    };
}

export function storeDriversAction(drivers) {
    return {
        type: STORE_DRIVERS,
        payload: drivers,
    };
}

export function setFilter(filterType, items) {
    return {
        type: PDC_SET_FILTER,
        payload: {
            filterType,
            items,
        },
    };
}

export function removeFilter(filter) {
    return {
        type: PDC_REMOVE_FILTER,
        payload: {
            filterType: filter.filterType,
            item: filter.item,
        },
    };
}

export function exportPdfAction(pdfUrl) {
    return {
        type: PDC_EXPORT_PDF,
        payload: {
            pdfUrl,
        },
    };
}

export function pdcFetchInspections() {
    return {
        type: PDC_FETCH_INSPECTIONS,
    };
}

export function pdcFetchMoreInspectionsAction(url) {
    return {
        type: PDC_FETCH_MORE_INSPECTIONS,
        payload: {
            url,
        },
    };
}

export function pdcSelectedAction(id) {
    return {
        type: PDC_SELECTED,
        payload: {
            id,
        },
    };
}

export function storeSelectedPdcAction(inspectionPayload) {
    return {
        type: STORE_SELECTED_PDC,
        payload: inspectionPayload,
    };
}

export function showPdcSidebarAction(display) {
    return {
        type: SHOW_PDC_SIDEBAR,
        payload: {
            display,
        },
    };
}

export function downloadPdcAttachmentAction(url) {
    return {
        type: DOWNLOAD_PDC_ATTACHMENT,
        payload: {
            url,
        },
    };
}

export function storePdcAttachmentAction({ url, content, contentType }) {
    return {
        type: STORE_PDC_ATTACHMENT,
        payload: {
            url,
            content,
            contentType,
        },
    };
}

export function downloadProductActivationsAction() {
    return {
        type: DOWNLOAD_PRODUCT_ACTIVATIONS,
    };
}

export function storeProductActivationsAction(activationStates) {
    return {
        type: STORE_PRODUCT_ACTIVATIONS,
        payload: {
            activationStates,
        },
    };
}

export function pdfDownloadIsPending(pdfUrl, analyticsStore) {
    return {
        type: PDF_DOWNLOAD_PENDING,
        payload: {
            pdfUrl,
            analyticsStore,
        },
    };
}

export function pdfDownloadIsFinished(pdfUrl) {
    return {
        type: PDF_DOWNLOAD_FINISHED,
        payload: {
            pdfUrl,
        },
    };
}

export function filterPdcBySelectedDriversAction(driverIds) {
    return {
        type: FILTER_PDC_BY_SELECTED_DRIVERS,
        payload: {
            driverIds,
        },
    };
}
