import { getAuthenticatedFetchJSON } from './fetch';

import getServiceLocation from '../lib/getServiceLocation';

const fetchTranslation = (injectedFetch = fetch, location = getServiceLocation('translation')) => {
    const configuredFetch = getAuthenticatedFetchJSON(injectedFetch);

    return configuredFetch(location);
};

export default fetchTranslation;
