export const DEFAULT_LOCALE = 'en-GB';

export const ROUTE_PATH_ENTRY = '/';
export const ROUTE_PATH_START = '/start';
export const ROUTE_PATH_TABLE = '/table';

export const BOOK_SERVICE_CARE_PATH = '/product/MAN-SKU00000049-10';

export const STORE_VEHICLES = 'STORE_VEHICLES';
export const STORE_DRIVERS = 'STORE_DRIVERS';
export const HIDE_SESSION_EXPIRED_DIALOG = 'HIDE_SESSION_EXPIRED_DIALOG';
export const PDC_FETCH_INSPECTIONS = 'PDC_FETCH_INSPECTIONS';
export const STORE_PDC_INSPECTIONS = 'STORE_PDC_INSPECTIONS';
export const PDC_FETCH_MORE_INSPECTIONS = 'PDC_FETCH_MORE_INSPECTIONS';
export const PDC_SET_FILTER = 'PDC_SET_FILTER';
export const PDC_REMOVE_FILTER = 'PDC_REMOVE_FILTER';
export const PDC_EXPORT_PDF = 'PDC_EXPORT_PDF';
export const PDC_SELECTED = 'PDC_SELECTED';
export const SHOW_PDC_SIDEBAR = 'SHOW_PDC_SIDEBAR';
export const STORE_SELECTED_PDC = 'STORE_SELECTED_PDC';
export const DOWNLOAD_PDC_ATTACHMENT = 'DOWNLOAD_PDC_ATTACHMENT';
export const STORE_PDC_ATTACHMENT = 'STORE_PDC_ATTACHMENT';
export const PDF_DOWNLOAD_PENDING = 'PDF_DOWNLOAD_PENDING';
export const PDF_DOWNLOAD_FINISHED = 'PDF_DOWNLOAD_FINISHED';
export const DOWNLOAD_PRODUCT_ACTIVATIONS = 'DOWNLOAD_PRODUCT_ACTIVATIONS';
export const STORE_PRODUCT_ACTIVATIONS = 'STORE_PRODUCT_ACTIVATIONS';

export const ATTACHMENT_RESOURCE = 'Attachment';
export const ISSUE_RESOURCE = 'Issue';

export const ASSET_TYPE_TRUCK = 'TRUCK';
export const ASSET_TYPE_TRAILER = 'TRAILER';
export const ASSET_TYPE_LICENCE_PLATE = 'LICENCE_PLATE';
export const ASSET_TYPE_VIN = 'VIN';
export const ASSET_TYPE_DRIVER = 'DRIVER';

export const PDC_SEVERITY_LOW = 'LOW';
export const PDC_SEVERITY_MEDIUM = 'MEDIUM';
export const PDC_SEVERITY_HIGH = 'HIGH';

export const PDC_SEVERITY_HIGH_CLASS_NAME = 'danger';
export const PDC_SEVERITY_MEDIUM_CLASS_NAME = 'warning';
export const PDC_SEVERITY_LOW_CLASS_NAME = '';

export const VEHICLE_CONDITION_FILTER = 'VEHICLE_CONDITION_FILTER';
export const DRIVER_FILTER = 'DRIVER_FILTER';

export const SHOW_CHANGELOG = 'SHOW_CHANGELOG';
export const HIDE_CHANGELOG = 'HIDE_CHANGELOG';
export const CHANGELOG_FILTER_NEW = 'CHANGELOG_FILTER_NEW';
export const CHANGELOG_FILTER_ALL = 'CHANGELOG_FILTER_ALL';

export const LOAD_OBJECT_TREE = 'LOAD_OBJECT_TREE';
export const TOGGLE_OBJECT_TREE = 'TOGGLE_OBJECT_TREE';
export const STORE_OBJECT_TREE_DRIVERS = 'STORE_OBJECT_TREE_DRIVERS';
export const FILTER_PDC_BY_SELECTED_DRIVERS = 'FILTER_PDC_BY_SELECTED_DRIVERS';

export const MAX_TABLE_ENTRIES = 40;
