import React from 'react';
import PropTypes from 'prop-types';

import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import DocumentTitle from 'react-document-title';

import { ROUTE_PATH_ENTRY, ROUTE_PATH_START, ROUTE_PATH_TABLE } from './constants';

import StartContainer from './containers/StartContainer';
import PDCContainer from './containers/PDCContainer';
import ActionsContainer from './ActionsContainer';

const AppRouter = ({ translations }) => (
    <ActionsContainer>
        <DocumentTitle title={'Pre-Departure Check'}>
            <HashRouter>
                <Routes>
                    <Route path={ROUTE_PATH_TABLE} element={<PDCContainer translations={translations} />} />
                    <Route path={ROUTE_PATH_START} element={<StartContainer translations={translations} />} />
                    <Route path={ROUTE_PATH_ENTRY} element={<Navigate to={ROUTE_PATH_TABLE} />} />
                </Routes>
            </HashRouter>
        </DocumentTitle>
    </ActionsContainer>
);

AppRouter.propTypes = {
    translations: PropTypes.object.isRequired,
};

export default AppRouter;
