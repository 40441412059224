import _ from 'lodash';
import { put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';

import {
    PDC_FETCH_MORE_INSPECTIONS,
    PDC_SELECTED,
    DOWNLOAD_PDC_ATTACHMENT,
    PDC_EXPORT_PDF,
    PDC_FETCH_INSPECTIONS,
} from '../constants';

import {
    storePdcInspections,
    storeSelectedPdcAction,
    storePdcAttachmentAction,
    pdfDownloadIsFinished,
} from '../actions/pdcActions';
import { fetchInspections, fetchInspection, fetchInspectionPdf } from '../fetch/fetchInspections';
import { getInspections, getFilters } from '../selectors';
import { fetchPdcAttachment } from '../fetch/fetchPdcAttachment';
import { downloadFile } from '../reducers/PDCReducer';
import { getLocale } from '../features/login/selector';

export function* doLoadPdf(action) {
    const { pdfUrl } = action.payload;
    const pdfBlob = yield call(fetchInspectionPdf, pdfUrl, fetch);
    yield call(downloadFile, pdfBlob);
    yield put(pdfDownloadIsFinished(pdfUrl));
}

export function* loadPdfSaga() {
    yield takeEvery(PDC_EXPORT_PDF, doLoadPdf);
}

export function* doFetchInspections() {
    const selectedFilter = yield select(getFilters);

    const fetchedInspections = yield call(fetchInspections, {
        injectedFetch: fetch,
        selectedFilter,
    });

    yield put(
        storePdcInspections({
            inspections: {
                links: fetchedInspections.links,
                items: fetchedInspections.items,
                totalItemCount: fetchedInspections.totalItemCount,
            },
        }),
    );
}

export function* fetchInspectionsSaga() {
    yield takeLatest(PDC_FETCH_INSPECTIONS, doFetchInspections);
}

export function* doFetchMoreInspections(action) {
    const inspections = yield select(getInspections);
    const fetchedInspections = yield call(fetchInspections, {
        injectedFetch: fetch,
        resourceUrl: decodeURIComponent(action.payload.url),
    });

    yield put(
        storePdcInspections({
            inspections: {
                links: fetchedInspections.links,
                items: _.get(inspections, 'items', []).concat(fetchedInspections.items),
                totalItemCount: fetchedInspections.totalItemCount,
            },
        }),
    );
}

export function* fetchMoreInspectionsSaga() {
    yield takeLatest(PDC_FETCH_MORE_INSPECTIONS, doFetchMoreInspections);
}

export function* doLoadPdcInformation(action) {
    const { payload } = action;
    const selectedInspection = payload.id;
    if (!selectedInspection) {
        return;
    }

    const locale = yield select(getLocale);

    const pdcInformation = yield call(fetchInspection, fetch, selectedInspection, locale);

    yield put(storeSelectedPdcAction(pdcInformation));
}

export function* loadPdcInformationSaga() {
    yield takeLatest(PDC_SELECTED, doLoadPdcInformation);
}

export function* doDownloadPdcAttachment(action) {
    const fetchedAttachment = yield call(fetchPdcAttachment, fetch, action.payload.url);

    yield put(
        storePdcAttachmentAction({
            url: action.payload.url,
            content: fetchedAttachment.file,
            contentType: fetchedAttachment.content_type,
        }),
    );
}

export function* downloadPdcAttachmentSaga() {
    yield takeEvery(DOWNLOAD_PDC_ATTACHMENT, doDownloadPdcAttachment);
}
