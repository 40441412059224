import { Children } from 'react';
import PropTypes from 'prop-types';

const AnalyticsStore = (props) => Children.only(props.children);

AnalyticsStore.propTypes = {
    data: PropTypes.object.isRequired,
};

export default AnalyticsStore;
