import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';

import { injectIntl, FormattedMessage } from 'react-intl';

import { hideChangeLog } from '../../actions/changelogActions';
import { getShowChangelog, getShowChangelogFilterLevel } from '../../selectors';

import ChangelogBody from './ChangelogBody';
import { getFilteredChangelog, WHATS_NEW_STORE_NAME, CURRENT_VERSION } from './ChangelogStaticContent';

const getFooterContent = (handleDialogHide) => (
    <button data-component={'CloseButton'} type={'button'} className={'btn btn-default'} onClick={handleDialogHide}>
        <FormattedMessage id={'changelog.close'} />
    </button>
);

const ChangelogComponentWithoutInjection = (props) => {
    const { handleDialogHide, show, filterLevel } = props;

    return (
        <Dialog
            show={show}
            title={<FormattedMessage id={'changelog.whatsNew'} />}
            body={<ChangelogBody entries={getFilteredChangelog(filterLevel)} />}
            onHide={handleDialogHide}
            useOverflow
            showCloseButton
            footer={getFooterContent(handleDialogHide)}
        />
    );
};

ChangelogComponentWithoutInjection.propTypes = {
    handleDialogHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    filterLevel: PropTypes.string.isRequired,
};

export const mapStateToProps = (state) => ({
    show: getShowChangelog(state),
    filterLevel: getShowChangelogFilterLevel(state),
});

export const mapDispatchToProps = (dispatch) => ({
    handleDialogHide: () => {
        window.localStorage.setItem(WHATS_NEW_STORE_NAME, CURRENT_VERSION);
        dispatch(hideChangeLog());
    },
});

const enhancedComponent = connect(mapStateToProps, mapDispatchToProps)(ChangelogComponentWithoutInjection);

export { ChangelogComponentWithoutInjection };
export default injectIntl(enhancedComponent);
