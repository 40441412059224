import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

export const StartPanelCardWithoutInjection = (props) => {
    const { imageUrl, headline, text, buttons } = props;

    return (
        <div className="thumbnail">
            <img alt={headline} className="img-responsive" src={imageUrl} />
            <hr />
            <div className="caption">
                <h2 className="margin-top-5">{headline}</h2>

                <p className={'text-size-large'}>{text}</p>

                {buttons.map((button) => button)}
            </div>
        </div>
    );
};

StartPanelCardWithoutInjection.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.node),
};

export default injectIntl(StartPanelCardWithoutInjection);
