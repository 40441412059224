/* eslint-disable react/prop-types, class-methods-use-this, no-confusing-arrow */
import React, { Component } from 'react';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { isEqual } from 'lodash/fp';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import AssetTree from '@rio-cloud/rio-uikit/lib/es/AssetTree';
import Tree from '@rio-cloud/rio-uikit/lib/es/Tree';
import TreeCategory from '@rio-cloud/rio-uikit/lib/es/TreeCategory';
import { injectIntl } from 'react-intl';
import { filterPdcBySelectedDriversAction } from '../actions/pdcActions';
import { toggleObjectTree } from '../actions/commonActions';
import PropTypes from 'prop-types';

const CATEGORY_DRIVER = 'drivers';
const CATEGORY_DRIVER_ICON = 'rioglyph-driver';

export class UnconnectedAppObjectTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDriverIds: [],
        };
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { locale, drivers, isToggled } = this.props;
        const { selectedDriverIds } = this.state;
        const isLanguageEqual = isEqual(nextProps.locale, locale);
        const isDriversEqual = isEqual(nextProps.drivers, drivers);
        const isSelectedDriverIdsEqual = isEqual(nextState.selectedDriverIds, selectedDriverIds);
        const isSidebarToggledEqual = isEqual(nextProps.isToggled, isToggled);
        return !isLanguageEqual || !isDriversEqual || !isSelectedDriverIdsEqual || !isSidebarToggledEqual;
    }

    handleSelectionChange({ items: selectedDriverIds }) {
        const { filterPdcBySelectedDrivers } = this.props;
        this.setState({
            selectedDriverIds,
        });
        filterPdcBySelectedDrivers(selectedDriverIds);
    }

    render() {
        const {
            drivers,
            intl: { formatMessage },
            isToggled,
            handleToggleTree,
        } = this.props;
        const { selectedDriverIds } = this.state;
        return (
            <ApplicationLayout.Sidebar>
                <AssetTree
                    bordered={false}
                    minWidth={300}
                    maxWidth={450}
                    currentCategoryId={CATEGORY_DRIVER}
                    isOpen={isToggled}
                    onToggleTree={handleToggleTree}
                    className={'remove-border full-height margin-bottom-0'}
                >
                    <TreeCategory
                        key={CATEGORY_DRIVER}
                        id={CATEGORY_DRIVER}
                        label={formatMessage({ id: 'objectTree.category.drivers' })}
                        icon={CATEGORY_DRIVER_ICON}
                        hasSelection={!isEmpty(selectedDriverIds)}
                    >
                        {!isEmpty(drivers) && (
                            <Tree
                                items={drivers}
                                selectedItems={selectedDriverIds}
                                onSelectionChange={this.handleSelectionChange}
                                hasMultiselect={!isEmpty(drivers)}
                                searchPlaceholder={formatMessage({ id: 'objectTree.search.placeholder.default' })}
                            />
                        )}
                    </TreeCategory>
                </AssetTree>
            </ApplicationLayout.Sidebar>
        );
    }
}

UnconnectedAppObjectTree.propTypes = {
    isToggled: PropTypes.bool,
    handleToggleTree: PropTypes.func,
};

export const mapStateToProps = (state) => ({
    drivers: state.objectTree.drivers,
    isToggled: state.objectTree.isToggled,
});

export const mapDispatchToProps = (dispatch) => ({
    filterPdcBySelectedDrivers: (driverIds) => dispatch(filterPdcBySelectedDriversAction(driverIds)),
    handleToggleTree: () => dispatch(toggleObjectTree()),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UnconnectedAppObjectTree));
