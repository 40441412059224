import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import SingleButtonDropdown from '@rio-cloud/rio-uikit/lib/es/SingleButtonDropdown';
import VehicleConditionFilter from './VehicleConditionFilter';

import { appendActionsAsItems } from './common/FileUtils';

export const ACTION_MENU_TITLE = 'actions';
export const CSV_EXPORT_TITLE = 'csvExport';

const getActions = (formatMessage, { downloadTableAsCSV }) => {
    const items = appendActionsAsItems([], formatMessage({ id: CSV_EXPORT_TITLE }), true, downloadTableAsCSV);
    return (
        <div className={'ActionsDropdownButton margin-bottom-10'}>
            <SingleButtonDropdown title={formatMessage({ id: ACTION_MENU_TITLE })} items={items} />
        </div>
    );
};

const getAllActions = (formatMessage, props) => (
    <div className={'row no-gutter'}>{getActions(formatMessage, props)}</div>
);

function getSelectedFilters(formatMessage, { filters, onFilterRemove }) {
    return (
        <ul className={'list-inline margin-top-10'}>
            {filters.map((entry) => (
                <li key={entry.item} className={'SelectedFilterTag'}>
                    <div
                        className={'tag tag-small deletable clickable rioglyph rioglyph-remove'}
                        onClick={() => {
                            onFilterRemove(entry);
                        }}
                    >
                        <span>{formatMessage({ id: `FILTER.VEHICLE_CONDITION.SEVERITY.${entry.item}` })}</span>
                    </div>
                </li>
            ))}
        </ul>
    );
}

function getAllFilters(formatMessage, props) {
    return (
        <div className={'panel panel-default panel-body'}>
            <VehicleConditionFilter filters={props.filters} />
            {getSelectedFilters(formatMessage, props)}
        </div>
    );
}

const TableFilterBarWithoutInjection = (props) => {
    const {
        intl: { formatMessage },
    } = props;

    return (
        <div className={'TableFilterBar'}>
            {getAllActions(formatMessage, props)}
            {getAllFilters(formatMessage, props)}
        </div>
    );
};

TableFilterBarWithoutInjection.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    downloadTableAsCSV: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    onFilterRemove: PropTypes.func.isRequired,
};

export { TableFilterBarWithoutInjection };

export default injectIntl(TableFilterBarWithoutInjection);
