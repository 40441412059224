import { LOAD_OBJECT_TREE, STORE_OBJECT_TREE_DRIVERS, TOGGLE_OBJECT_TREE } from '../constants';

export function loadObjectTreeAction() {
    return {
        type: LOAD_OBJECT_TREE,
        payload: {},
    };
}

export const toggleObjectTree = () => {
    return {
        type: TOGGLE_OBJECT_TREE,
        payload: {},
    };
};

export function storeObjectTreeDrivers(drivers) {
    return {
        type: STORE_OBJECT_TREE_DRIVERS,
        payload: {
            drivers,
        },
    };
}
