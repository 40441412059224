export const USER_SESSION_EXPIRED = 'login/USER_SESSION_EXPIRED';
export const USER_SESSION_RENEWED = 'login/USER_SESSION_RENEWED';

export const userSessionExpired = () => ({
    type: USER_SESSION_EXPIRED,
});

export const userSessionRenewed = (result) => ({
    type: USER_SESSION_RENEWED,
    payload: {
        result,
    },
});
