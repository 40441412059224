import { STORE_OBJECT_TREE_DRIVERS } from '../constants';

const getInitialState = () => ({
    data: {
        drivers: [],
        driverGroups: [],
    },
});

const CommonReducer = (state = getInitialState(), action = {}) => {
    switch (action.type) {
        case STORE_OBJECT_TREE_DRIVERS:
            return {
                data: {
                    ...state.data,
                    drivers: action.payload.drivers,
                },
            };
        default:
            return state;
    }
};

export default CommonReducer;
