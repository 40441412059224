import { CHANGELOG_FILTER_ALL } from '../../constants';

import whatsNew from './whatsNew.json';

export const getWhatsNew = () => whatsNew;

const lastReleaseIndex = 0;
export const CURRENT_VERSION = getWhatsNew()[lastReleaseIndex].releaseNumber;
export const WHATS_NEW_STORE_NAME = 'PREDEPARTURECHECK.LASTVERSIONSEEN';

const getLastSeen = () => window.localStorage.getItem(WHATS_NEW_STORE_NAME);
export const hasNewVersion = () => getLastSeen() < CURRENT_VERSION;
export const getFilteredChangelog = (filterLevel = CHANGELOG_FILTER_ALL) => {
    const unseen =
        filterLevel === CHANGELOG_FILTER_ALL
            ? getWhatsNew()
            : getWhatsNew().filter((entry) => entry.releaseNumber > getLastSeen());

    return unseen;
};
