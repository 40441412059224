import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE } from '../constants';

import generateActiveMessages from '../lib/generateActiveMessages';
import StartPageComponent from '../components/StartPageComponent';
import ApplicationBody from '../components/ApplicationBody';
import { getLocale } from '../features/login/selector';

export const UnconnectedStartContainer = (props) => {
    const { activeMessages, language, ...rest } = props;

    return (
        <ApplicationBody locale={language} activeMessages={activeMessages} hideDriverSidebar={true}>
            <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={language} messages={activeMessages}>
                <StartPageComponent {...rest} />
            </IntlProvider>
        </ApplicationBody>
    );
};

UnconnectedStartContainer.propTypes = {
    activeMessages: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
};

export const mapStateToProps = (state, ownProps) => {
    const { translations } = ownProps;
    const locale = getLocale(state);
    return {
        activeMessages: generateActiveMessages(locale, translations),
        language: locale,
    };
};

const StartContainer = connect(mapStateToProps)(UnconnectedStartContainer);

export default StartContainer;
