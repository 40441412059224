import { put, call, takeLatest } from 'redux-saga/effects';

import { DOWNLOAD_PRODUCT_ACTIVATIONS } from '../constants';
import { storeProductActivationsAction } from '../actions/pdcActions';
import fetchProductActivations from '../fetch/fetchProductActivations';

export function* doDownloadProductActivations() {
    const responseBody = yield call(fetchProductActivations, fetch);
    const items = (responseBody && responseBody.items) || [];

    yield put(storeProductActivationsAction(items));
}

export function* downloadProductActivationsSaga() {
    yield takeLatest(DOWNLOAD_PRODUCT_ACTIVATIONS, doDownloadProductActivations);
}
