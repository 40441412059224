import { takeLatest, put, select } from 'redux-saga/effects';

import { LOAD_OBJECT_TREE, DRIVER_FILTER, FILTER_PDC_BY_SELECTED_DRIVERS } from '../constants';
import { setFilter, pdcFetchInspections } from '../actions/pdcActions';
import { storeObjectTreeDrivers } from '../actions/commonActions';
import { getDrivers } from '../selectors';

export function* doLoadObjectTree() {
    const drivers = yield select(getDrivers);
    yield put(storeObjectTreeDrivers(drivers));
}

export function* loadObjectTreeSaga() {
    yield takeLatest(LOAD_OBJECT_TREE, doLoadObjectTree);
}

export function* doFilterPDCBySelectedDrivers(action) {
    yield put(setFilter(DRIVER_FILTER, action.payload.driverIds));
    yield put(pdcFetchInspections());
}

export function* filterPDCBySelectedDrivers() {
    yield takeLatest(FILTER_PDC_BY_SELECTED_DRIVERS, doFilterPDCBySelectedDrivers);
}
