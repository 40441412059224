import getEnvironment from './getEnvironment';

const EXPRESS_SERVER_PORT = 8080;
const EXPRESS_SERVER_URL = `http://localhost:${EXPRESS_SERVER_PORT}`;

/* eslint-disable max-len */
const serviceLocations = {
    translation: {
        local: `${EXPRESS_SERVER_URL}/translations.json`,
        production: '/translations.json',
    },
    home: {
        local: `${EXPRESS_SERVER_URL}`,
        production: 'https://home.rio.cloud',
    },
    redirect: {
        local: 'http://localhost:3000/redirect.html',
        production: 'https://pdc.vehicleinspection.rio.cloud/redirect.html',
    },
    authority: {
        local: `${EXPRESS_SERVER_URL}`,
        production: 'https://auth.iam.rio.cloud',
    },
    logout: {
        local: '/',
        production:
            'https://auth.iam.rio.cloud/logout?redirect_uri=https%3A%2F%2Fpdc.vehicleinspection.rio.cloud%2Fredirect.html',
    },
    vehicleinspection: {
        local: `${EXPRESS_SERVER_URL}/vehicleinspection`,
        production: 'https://api.vehicleinspection.rio.cloud',
    },
    assetsApi: {
        local: `${EXPRESS_SERVER_URL}/assets-api`,
        production: 'https://api.assets.rio.cloud',
    },
    servicecareproductactivation: {
        local: `${EXPRESS_SERVER_URL}/productactivation`,
        production: 'https://api.vehiclestate.rio.cloud',
    },
    marketplace: {
        local: 'https://marketplace.rio.cloud',
        production: 'https://marketplace.rio.cloud',
    },
    damageReport: {
        production: 'https://manservicecare.rio.cloud/#damages',
        local: 'https://servicecareadmin.maintenance.int-ccp-dev.net/servicecareadministration/damagereports',
    },
    menu: {
        local: `${EXPRESS_SERVER_URL}/menu`,
        production: 'https://menu.rio.cloud',
    },
    vehicleAdmin: {
        local: 'https://administration.assets.rio.cloud/#assets',
        production: 'https://administration.assets.rio.cloud/#assets',
    },
};
/* eslint-enable max-len */

const getServiceLocation = (serviceName, injectedEnvironment = getEnvironment()) => {
    const serviceLocation = serviceLocations[serviceName] || {};
    return serviceLocation[injectedEnvironment] || null;
};

export default getServiceLocation;
