import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { exportPdfAction, pdfDownloadIsPending } from '../actions/pdcActions';
import { getPDCDownloads } from '../selectors';

const PDFDownloadButtonComponentWithoutInjection = (props) => {
    const { formatMessage, locale, pdfUrl, exportPdf, setDownloadPending, pdcDownloads, analyticsStore } = props;

    const urlWithLocale = `${pdfUrl}?locale=${locale}`;
    const showLoadingSpinner = pdcDownloads && pdcDownloads[urlWithLocale];

    // using this ensures that line height does not change while loading the PDF
    // and also that line height is not smaller if pdfUrl does not exist
    const hiddenButtonPlaceholder = (
        <button className={'invisible btn btn-link btn-inline'}>
            <i className={'rioglyph rioglyph-pdf-file text-size-h3'} />
        </button>
    );

    if (!pdfUrl) {
        return hiddenButtonPlaceholder;
    }

    if (showLoadingSpinner) {
        return (
            <div className={'display-flex justify-content-center align-items-center'}>
                {hiddenButtonPlaceholder}
                <div className={'spinner'} />
                {hiddenButtonPlaceholder}
            </div>
        );
    }

    return (
        <button
            className={'btn btn-link btn-inline'}
            onClick={(event) => {
                event && event.stopPropagation(); // avoid triggering other onClick handlers besides this one
                setDownloadPending(urlWithLocale, analyticsStore);
                exportPdf(`${pdfUrl}?locale=${locale}`);
            }}
        >
            <i className={'rioglyph rioglyph-pdf-file text-size-h3'} /> {formatMessage({ id: 'download' })}
        </button>
    );
};

PDFDownloadButtonComponentWithoutInjection.propTypes = {
    formatMessage: PropTypes.func.isRequired,
    locale: PropTypes.string.isRequired,
    exportPdf: PropTypes.func.isRequired,
    pdfUrl: PropTypes.string.isRequired,
    setDownloadPending: PropTypes.func.isRequired,
    pdcDownloads: PropTypes.func.isRequired,
    analyticsStore: PropTypes.any,
};

export const mapStateToProps = (state) => ({
    pdcDownloads: getPDCDownloads(state),
});

export const mapDispatchToProps = (dispatch) => ({
    exportPdf: (url) => dispatch(exportPdfAction(url)),
    setDownloadPending: (pdfUrl, analyticsStore) => dispatch(pdfDownloadIsPending(pdfUrl, analyticsStore)),
});

export { PDFDownloadButtonComponentWithoutInjection };

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PDFDownloadButtonComponentWithoutInjection));
