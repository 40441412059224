import React from 'react';
import { FormattedMessage } from 'react-intl';

const StartTopPanel = () => {
    return (
        <div className={'panel panel-default start-top-panel'}>
            <div className={'panel-body padding-25-sm'}>
                <div className={'row equal-height'}>
                    <div className={'col-lg-4 col-md-5 col-sm-6'}>
                        <img className={'img-responsive'} alt={'Start Image'} src={'./images/start-top.png'} />
                    </div>
                    <div className={'col-lg-8 col-md-7 col-sm-6 flex flex-column justify-content-center'}>
                        <h2>
                            <FormattedMessage id={'startpage.digitalPreDepartureCheck.title'} />
                        </h2>
                        <p className={'padding-right-25-sm text-size-large'}>
                            <FormattedMessage id={'startpage.digitalPreDepartureCheck.text'} />
                            <br />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartTopPanel;
