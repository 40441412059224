import { getAuthenticatedFetchJSON } from './fetch';

import getServiceLocation from '../lib/getServiceLocation';

const fetchProductActivations = (injectedFetch = fetch) => {
    const configuredFetch = getAuthenticatedFetchJSON(injectedFetch);

    return configuredFetch(`${getServiceLocation('servicecareproductactivation')}/activation-states`).catch(() => {
        return {};
    });
};

export default fetchProductActivations;
