import { captureException } from '@sentry/browser';

import getEnvironment from './getEnvironment';

const handleWhiteScreenOfDeath = (error, id, injectedEnvironment = getEnvironment(), injectedDocument = document) => {
    console.log('Pre-departure check could not be loaded', error); // eslint-disable-line no-console
    captureException(error);
    const element = injectedDocument.getElementById(id);
    if (injectedEnvironment === 'production') {
        element.innerHTML = '<h1>Pre departure check not working... Sorry :-(</h1>';
    } else {
        element.innerHTML = `<h1>Pre departure check not working... Sorry :-(</h1>${error.message}${error.stack}`;
    }
};

export default handleWhiteScreenOfDeath;
