/* eslint-env node */
import { Log, UserManager } from 'oidc-client';

export const handleLoginRedirect = (injectedWindow) => {
    let runsInIframe = false;
    if (injectedWindow !== undefined) {
        runsInIframe = injectedWindow && injectedWindow.parent && injectedWindow.parent !== injectedWindow;
    } else {
        runsInIframe = window && window.parent && window.parent !== window;
    }

    if (runsInIframe) {
        // Silent redirect within an <iframe>
        Log.logger = console;
        Log.level = Log.INFO;

        // This will propagate the received information provided via
        // query parameters to the parent frame
        new UserManager({}).signinSilentCallback();
    } else {
        window.location.replace(`${window.location.origin}#/?${window.location.hash.replace('#', '')}`);
        // OLD VERSION: window.location.href.replace('/redirect.html', '/index.html');
    }
};
