import { PDC_SET_FILTER, PDC_REMOVE_FILTER } from '../constants';

const FilterReducer = (state = [], action = {}) => {
    const filterType = action.payload && action.payload.filterType;

    switch (action.type) {
        case PDC_SET_FILTER:
            return state
                .filter((entry) => entry.filterType !== filterType)
                .concat(action.payload.items.map((entry) => ({ filterType, item: entry })));
        case PDC_REMOVE_FILTER:
            return state.filter((entry) => !(entry.filterType === filterType && entry.item === action.payload.item));
        default:
            return state;
    }
};

export default FilterReducer;
