import root from 'window-or-global';
import { takeEvery } from 'redux-saga/effects';

import { PDF_DOWNLOAD_PENDING } from '../constants';

/*
    type params = {
        element_name: string;
        component_name: 'ApplicationLayout' |
            'ApplicationHeader' |
            'BottomSheet' |
            'Sidebar' |
            'MainNavigation' |
            'SubNavigation' |
            'AppNavigationBar' |
            'Pager' |
            'Button' |
            'Checkbox' |
            'Clearablelnput' |
            'LoadMoreButton' |
            'NumberControl' |
            'RadioButton ' |
            'Slider' |
            'Switch' |
            'ToggleButton' |
            'Collapse' |
            'Expander' |
            'Fade' |
            'AssetTree' |
            'AutoSuggest' |
            'ButtonDropdown' |
            'Select' |
            'DatePicker' |
            'FilePicker' |
            'TimePicker' |
            'StatusBar' |
            'SteppedProgress ' |
            'AnimatedNumber' |
            'Carousel' |
            'ContentLoader' |
            'DataTabs' |
            'Dialog' |
            'NoData' |
            'ErrorState' |
            'NotFoundState' |
            'EmptyState' |
            'ForbiddenState' |
            'MaintenanceState' |
            'CustomState' |
            'NotBookedState' |
            'StatsWidget' |
            'TableToolbar' |
            'TableSearch' |
            'TableViewToggles' |
            'TableCardsSorting' |
            'TableSettingDialog' |
            'Teaser' |
            'Timeline' |
            'AreaChart' |
            'LineChart' |
            'BarChart' |
            'PieChart' |
            'RadialBarChart' |
            'Map' |
            'SingleMapMarker' |
            'ClusterMapMarker' |
            'Marker'
        trigger: 'click' | 'visibility';
        flow_name?: string | undefined;
        identification_type?: string | undefined;
        connection_type?: 'inbound' | 'outbound' | undefined;
        connection_brand?: 'scania' | 'idem' | 'fleetboard' | 'volvo' | undefined;
        components?: number | undefined;
        method?: string | undefined;
        search_term?: string | undefined;
        currency?: 'Eur' | 'Usd' | undefined;
        value?: number | undefined;
        page_location?: string | undefined;
        page_title?: string | undefined;
        ecommerce?: object | undefined;
        workshop_id?: string | undefined;
        asset_id?: string | undefined;
        rating?: number | undefined;
        callback_requested?: 'yes' | 'no' | undefined;
    }
 */

/*
    type userProps = {
        login_method?: 'mobile' | 'email' | undefined;
        account_id?: string | undefined;
        tenant?: string | undefined; // rio-eu.test, rio-eu.prod, ...
        user_id?: string | undefined;
    };
 */

export const AnalyticsEvents = {
    pdcDownloadWithoutDamages: 'pdc_download_without_damages',
    pdcDownloadWithDamages: 'pdc_download_with_damages',
};

export function doTrackPDFDownload(action) {
    const { pdc } = action.payload.analyticsStore;
    const eventName = pdc.totalDamages
        ? AnalyticsEvents.pdcDownloadWithDamages
        : AnalyticsEvents.pdcDownloadWithoutDamages;

    root.dataLayer.push(eventName, {
        element_name: 'PDFDownloadButtonComponent',
        component_name: 'Button',
        trigger: 'click',
        page_location: window.location.href,
    });
}

export function* trackPDFDownload() {
    yield takeEvery(PDF_DOWNLOAD_PENDING, doTrackPDFDownload);
}
