import { SHOW_CHANGELOG, HIDE_CHANGELOG } from '../constants';

export function showChangeLog() {
    return {
        type: SHOW_CHANGELOG,
        payload: {},
    };
}

export function hideChangeLog() {
    return {
        type: HIDE_CHANGELOG,
        payload: {},
    };
}
