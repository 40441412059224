/* eslint-disable camelcase */
import { Log, UserManager } from 'oidc-client';
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';

import getServiceLocation from '../../lib/getServiceLocation';
import { DEFAULT_LOCALE } from '../../constants';

import { configureStorage } from './storage';

export const SIGNIN_REQUESTED = 'SIGNIN_REQUESTED';

const featureTracing = false;

const trace = featureTracing
    ? // eslint-disable-next-line no-console
      (...args) => console.log(`[oidcLogin]`, ...args)
    : _.noop;

const getWindow = () => window || {};

const param = (window, regex, defaultValue = null) => {
    let result = defaultValue;
    decodeURI(window.location.href).replace(regex, (match, it) => {
        result = it;
    });
    return result;
};

export const adaptPublishedInfo = (result = {}) => ({
    accessToken: result.access_token,
    expiresInSeconds: result.expires_in,
    locale: _.get(result, 'profile.locale', DEFAULT_LOCALE),
    profile: result.profile,
});

export const configureAuth = () => {
    const { redirectUri, silentRedirectUri } = { redirectUri: getServiceLocation('redirect') };

    const settings = {
        authority: getServiceLocation('authority'),
        client_id: '5262260a-9184-4419-91ff-7f5e00e3f167',
        loadUserInfo: false,
        redirect_uri: `${redirectUri}`,
        response_type: `code`,
        scope: ' ', // 'hack to get all subscriptions (https://my-rio.slack.com/archives/CMCQ4HSHH/p1572856830003300)
        silent_redirect_uri: `${silentRedirectUri || redirectUri}`,
    };

    trace('oidc.auth.settings', settings);

    return new UserManager(settings);
};

// eslint-disable-next-line max-lines-per-function
export const configureSetupOAuth = (auth, storage, window) => {
    if (featureTracing) {
        Log.logger = console;

        Log.level = Log.DEBUG;
    }

    const isFreshRedirect = Boolean(param(window, /code=([^&]+)/u));

    const saveCurrentRoute = () => {
        const initialRoute = [window.location.hash, window.location.search].join('').replace(/^#/u, '');

        storage.saveRoute(initialRoute);

        trace('saving initial route', initialRoute);
    };

    // eslint-disable-next-line max-lines-per-function
    return (config) => {
        const trySignin = () =>
            auth
                .signinSilent()
                .then((result) => {
                    trace('oidc.signinSilent success!', result);
                    config.onTokenRenewed(adaptPublishedInfo(result));

                    if (!isFreshRedirect) {
                        saveCurrentRoute();
                    }

                    return result;
                })
                .catch((error) => {
                    trace('oidc.signinSilent failed', error);

                    if (!isFreshRedirect) {
                        saveCurrentRoute();
                    }

                    config.onTokenExpired();
                    return Promise.reject(error);
                });

        // eslint-disable-next-line no-warning-comments
        // TODO: Move to avoid this getting attached multiple times.
        if (_.get(window, 'document.addEventListener')) {
            window.document.addEventListener(SIGNIN_REQUESTED, () => {
                trace('[features/login/signinrequested] Trying to sign in silently...');
                trySignin()
                    .then((result) => {
                        trace('[features/login/signinrequested] Re-Signin successful.', result);
                    })
                    .catch((error) => {
                        trace('[features/login/signinrequested] Re-Signin failed.', error);
                    });
            });
        }

        auth.events.addAccessTokenExpiring((...args) => {
            trace('oidc.accessTokenExpiring', ...args);
            trace('  triggering manual silent renewal...');

            saveCurrentRoute();
            return trySignin();
        });

        auth.events.addAccessTokenExpired((...args) => {
            trace('oidc.accessTokenExpired', ...args);
            config.onTokenExpired();
        });

        auth.events.addSilentRenewError((error) => {
            trace('oidc.silentRenewError', error);
            config.onSessionError(error);
            config.onTokenExpired();
        });

        auth.events.addUserLoaded((...args) => {
            trace('oidc.userLoaded', ...args);
        });

        auth.events.addUserSignedOut((...args) => {
            trace('oidc.userSignedOut', ...args);
            config.onTokenExpired();
        });

        const mightBeSuspicious = isFreshRedirect;

        return trySignin().catch((error) => {
            trace('oidc.signinSilent failed, trying page redirect...', error);

            if (mightBeSuspicious) {
                trace('oidc.signinSilent.error', 'redirect prevented due to supsicious signin error', error);
                storage.discardRoute();
                config.onSessionError(error);
            } else {
                saveCurrentRoute();
                auth.signinRedirect();
            }

            return Promise.reject(new Error(`Need to sign in`));
        });
    };
};

const runtimeAuth = configureAuth();
trace(`runtimeAuth`, runtimeAuth);

export const setupOAuth = configureSetupOAuth(runtimeAuth, configureStorage(getWindow()), getWindow());

export const mockOAuth = ({ onTokenRenewed }, mockAuthorization) => {
    // eslint-disable-next-line no-console
    console.warn(`[feature/login/oidc-session] Using mocked authorization due to config setting`);

    onTokenRenewed(
        adaptPublishedInfo({
            access_token: mockAuthorization.testAccessToken || 'valid-mocked-oauth-bogus-token',
            // eslint-disable-next-line no-magic-numbers
            expires_in: 60 * 60 * 24 * 365,
            profile: mockAuthorization.testIdToken
                ? jwtDecode(mockAuthorization.testIdToken)
                : {
                      account: 'mockaccount',
                      azp: 'test-client',
                      email: 'test@example.com',
                      family_name: 'Client',
                      given_name: 'Test',
                      locale: DEFAULT_LOCALE,
                      name: 'Test Client',
                      sub: 'prod-rio-users:mock-user',
                  },
        }),
    );

    return Promise.resolve();
};

export const configureRetrieveInitialState = (storage) => () => ({
    initialRoute: storage.getRoute(),
});

export const retrieveInitialState = configureRetrieveInitialState(configureStorage(getWindow()));
