/* eslint-disable max-lines-per-function */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import Sidebar from '@rio-cloud/rio-uikit/lib/es/Sidebar';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage, injectIntl } from 'react-intl';

import { getPdcSidebar, getSelectedInspection } from '../../selectors';
import { showPdcSidebarAction } from '../../actions/pdcActions';

import PDFDownloadButtonComponent from '../PDFDownloadButtonComponent';
import AnalyticsStore from '../AnalyticsStore';

import PDCReportContainer from './PDCReportContainer';

const BaseComponent = (props) => {
    const {
        intl: { formatMessage, locale },
        pdc,
    } = props;
    const { pdfUrl } = pdc || {};

    return (
        <ApplicationLayout.Sidebar className={'right'}>
            <Sidebar
                fly
                title={<FormattedMessage id={'report.pdc.reportTitle'} />}
                titleClassName={'padding-left-10'}
                closed={!props.display}
                onClose={() => props.showSidebar(false)}
                width={460}
                footer={
                    <AnalyticsStore data={{ eventCategory: 'sidebar', pdc }}>
                        <PDFDownloadButtonComponent formatMessage={formatMessage} locale={locale} pdfUrl={pdfUrl} />
                    </AnalyticsStore>
                }
            >
                <div className={'padding-left-20 padding-right-20'}>
                    {pdc ? <PDCReportContainer {...props} /> : <Spinner />}
                </div>
            </Sidebar>
        </ApplicationLayout.Sidebar>
    );
};

BaseComponent.propTypes = {
    pdc: PropTypes.object.isRequired,
    display: PropTypes.bool,
    showSidebar: PropTypes.any,
    intl: PropTypes.any,
};

export const mapStateToProps = (state) => ({
    pdc: getSelectedInspection(state),
    display: getPdcSidebar(state).display || false,
});

export const mapDispatchToProps = (dispatch) => ({
    showSidebar: (display) => dispatch(showPdcSidebarAction(display)),
});

export { BaseComponent };
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BaseComponent));
