import 'es6-promise/auto';
import 'isomorphic-fetch';
import _ from 'lodash';
import { accessToken } from '../features/tokenHandling/accessToken';

function FetchResponseError(response, body) {
    Error.call(this);
    this.name = 'FetchResponseError';
    this.message = response.statusText;
    this.status = response.status;
    this.body = body;
}

function ParseResponseError(message, rawString, url) {
    Error.call(this);
    this.name = 'ParseResponseError';
    this.message = message;
    this.rawString = rawString;
    this.url = url;
}

function validateFetch(fetch) {
    if (!fetch || !_.isFunction(fetch)) {
        throw new Error('fetch needs to be injected');
    }
}

function validateDocument(document) {
    if (!document || !document.dispatchEvent || !_.isFunction(document.dispatchEvent)) {
        throw new Error('document needs to be injected');
    }
}

function fixRequestObject(requestObject) {
    if (!_.isObject(requestObject)) {
        return {};
    }

    return requestObject;
}

function enrichRequestObject(requestObject) {
    const authorizationBearer = `Bearer ${accessToken.getAccessToken()}`;

    const requestObjectFixed = fixRequestObject(requestObject);

    if (typeof requestObjectFixed.headers === 'undefined') {
        requestObjectFixed.headers = {
            Authorization: authorizationBearer,
        };
    } else {
        requestObjectFixed.headers['Authorization'] = authorizationBearer;
    }

    return requestObjectFixed;
}

function parseRequestObjectBody(requestObject) {
    const requestObjectFixed = fixRequestObject(requestObject);

    if (requestObjectFixed && _.isObject(requestObjectFixed.body)) {
        requestObjectFixed.body = JSON.stringify(requestObjectFixed.body);
    }

    return requestObjectFixed;
}

function rejectResponse(response, injectedDocument) {
    if (response.text) {
        return response.text().then(function (message) {
            const error = new FetchResponseError(response, message);
            const event = new CustomEvent('FetchResponseError', { detail: error });
            injectedDocument.dispatchEvent(event);

            return Promise.reject(error);
        });
    }
    const error = new FetchResponseError(response, '');
    const event = new CustomEvent('FetchResponseError', { detail: error });
    injectedDocument.dispatchEvent(event);

    return Promise.reject(error);
}

function handleTextResponse(response, injectedDocument) {
    if (response.ok) {
        return response.text().then(function (message) {
            return Promise.resolve(message);
        });
    }
    return rejectResponse(response, injectedDocument);
}

function handleRawResponse(response, injectedDocument) {
    if (response.ok) {
        return Promise.resolve(response);
    }
    return rejectResponse(response, injectedDocument);
}

export function authenticatedFetch(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);

    const requestObjectEnriched = enrichRequestObject(requestObject);
    const requestObjectParsed = parseRequestObjectBody(requestObjectEnriched);

    return injectedFetch(requestUrl, requestObjectParsed).then(function (response) {
        return handleTextResponse(response, injectedDocument);
    });
}

export function authenticatedFetchRaw(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);

    const requestObjectEnriched = enrichRequestObject(requestObject);
    const requestObjectParsed = parseRequestObjectBody(requestObjectEnriched);

    return injectedFetch(requestUrl, requestObjectParsed).then(function (response) {
        return handleRawResponse(response, injectedDocument);
    });
}

export function authenticatedFetchJSON(requestUrl, requestObject = {}, injectedFetch, injectedDocument) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);

    if (!requestObject.headers) {
        requestObject.headers = {};
    }

    requestObject.headers.Accept = 'application/json';

    const requestObjectParsed = parseRequestObjectBody(requestObject);

    return authenticatedFetch(requestUrl, requestObjectParsed, injectedFetch, injectedDocument).then(
        function (response) {
            try {
                if (!response) {
                    return Promise.resolve({});
                }

                const parsedResult = JSON.parse(response);
                return Promise.resolve(parsedResult);
            } catch (parseError) {
                return Promise.reject(new ParseResponseError(parseError.message, response, requestUrl));
            }
        },
    );
}

export function getAuthenticatedFetchJSON(injectedFetch = fetch, injectedDocument = document) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);

    return function (requestUrl, requestObject) {
        return authenticatedFetchJSON(requestUrl, requestObject, injectedFetch, injectedDocument);
    };
}

export function getAuthenticatedFetchRaw(injectedFetch = fetch, injectedDocument = document) {
    validateFetch(injectedFetch);
    validateDocument(injectedDocument);

    return function (requestUrl, requestObject) {
        return authenticatedFetchRaw(requestUrl, requestObject, injectedFetch, injectedDocument);
    };
}

export function polyfillCustomEvent(injectedWindow = window) {
    if (typeof injectedWindow.CustomEvent === 'function') {
        return false;
    }

    function CustomEvent(event, params) {
        const paramsExt = params || { bubbles: false, cancelable: false, detail: undefined };
        const evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, paramsExt.bubbles, paramsExt.cancelable, paramsExt.detail);
        return evt;
    }

    CustomEvent.prototype = injectedWindow.Event.prototype;

    injectedWindow.CustomEvent = CustomEvent;
}

polyfillCustomEvent();
