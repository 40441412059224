/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';

import { getPdcAttachments } from '../../selectors';
import { downloadPdcAttachmentAction } from '../../actions/pdcActions';

const AttachmentSpinner = () => (
    <div className={'attachment content-center height-100'}>
        <Spinner />
    </div>
);

const BaseComponent = (props) => {
    const { resourceUrl, contentType, content, downloadAttachment } = props;
    const isLoading = content === undefined || contentType === undefined;

    useEffect(() => {
        downloadAttachment(resourceUrl);
    }, []);

    return (
        <div>
            {isLoading && <AttachmentSpinner />}
            {!isLoading && (
                <div className={'attachment margin-right-2'}>
                    <img src={`data:${contentType};base64, ${content}`} />
                </div>
            )}
        </div>
    );
};

BaseComponent.propTypes = {
    resourceUrl: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    downloadAttachment: PropTypes.func.isRequired,
};

export const mapStateToProps = (state, ownProps) => ({
    content: (getPdcAttachments(state)[ownProps.resourceUrl] || {}).content,
    contentType: (getPdcAttachments(state)[ownProps.resourceUrl] || {}).contentType,
});

export const mapDispatchToProps = (dispatch) => ({
    downloadAttachment: (url) => dispatch(downloadPdcAttachmentAction(url)),
});

export { BaseComponent };
export default connect(mapStateToProps, mapDispatchToProps)(BaseComponent);
