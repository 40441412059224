import React from 'react';
import { PDC_SEVERITY_HIGH, PDC_SEVERITY_LOW, PDC_SEVERITY_MEDIUM, VEHICLE_CONDITION_FILTER } from '../constants';
import { connect } from 'react-redux';
import Multiselect from '@rio-cloud/rio-uikit/lib/es/Multiselect';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { getPDCIsLoading } from '../selectors';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { setFilter, pdcFetchInspections } from '../actions/pdcActions';

const isOptionSelected = (filters, item) => {
    const negativeIndex = -1;
    return filters.indexOf(item) > negativeIndex;
};

const VehicleConditionFilter = ({ filters, isLoading, onVehicleConditionFilterChange }) => {
    const enabledVehicleConditionFilters = filters
        .filter((entry) => entry.filterType === VEHICLE_CONDITION_FILTER)
        .map((entry) => entry.item);

    const vehicleConditionOptions = [
        {
            id: PDC_SEVERITY_LOW,
            label: <FormattedMessage id={'FILTER.VEHICLE_CONDITION.SEVERITY.LOW'} />,
            selected: isOptionSelected(enabledVehicleConditionFilters, PDC_SEVERITY_LOW),
        },
        {
            id: PDC_SEVERITY_MEDIUM,
            label: <FormattedMessage id={'FILTER.VEHICLE_CONDITION.SEVERITY.MEDIUM'} />,
            selected: isOptionSelected(enabledVehicleConditionFilters, PDC_SEVERITY_MEDIUM),
        },
        {
            id: PDC_SEVERITY_HIGH,
            label: <FormattedMessage id={'FILTER.VEHICLE_CONDITION.SEVERITY.HIGH'} />,
            selected: isOptionSelected(enabledVehicleConditionFilters, PDC_SEVERITY_HIGH),
        },
    ];

    return (
        <div className={'row'}>
            <div className={'col-md-4 vehicleConditionFilter'}>
                <label className={'control-label'}>
                    <FormattedMessage id={'FILTER.VEHICLE_CONDITION.TITLE'} />
                </label>
                <div className={'display-flex align-items-center'}>
                    <Multiselect
                        placeholder={<FormattedMessage id={'FILTER.PLACEHOLDER'} />}
                        options={vehicleConditionOptions}
                        onChange={onVehicleConditionFilterChange}
                        counterMessage={{
                            one: <FormattedMessage id={'FILTER.VEHICLE_CONDITION.ONE_SELECTED'} />,
                            many: <FormattedMessage id={'FILTER.VEHICLE_CONDITION.MANY_SELECTED'} />,
                        }}
                    />
                    <div className={'margin-left-10'}>{isLoading && <Spinner />}</div>
                </div>
            </div>
        </div>
    );
};

VehicleConditionFilter.propTypes = {
    isLoading: PropTypes.bool,
    filters: PropTypes.array.isRequired,
    onVehicleConditionFilterChange: PropTypes.func,
};

export const mapStateToProps = (state) => ({
    isLoading: getPDCIsLoading(state),
});

export const mapDispatchToProps = (dispatch) => ({
    onVehicleConditionFilterChange: (items) => {
        dispatch(setFilter(VEHICLE_CONDITION_FILTER, items));
        dispatch(pdcFetchInspections());
    },
});

export { VehicleConditionFilter };
export default connect(mapStateToProps, mapDispatchToProps)(VehicleConditionFilter);
