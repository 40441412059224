import root from 'window-or-global';
import { get } from 'lodash';

const getEnvironment = (injectedWindow = window, injectedRoot = root) => {
    const injectedEnvironment = get(injectedRoot, 'window.query.environment');
    if (injectedEnvironment) {
        return injectedEnvironment;
    } else if (injectedWindow.location.host === 'pdc.vehicleinspection.rio.cloud') {
        return 'production';
    }

    return 'local';
};

export default getEnvironment;
