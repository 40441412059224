import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SortArrows from '@rio-cloud/rio-uikit/lib/es/SortArrows';
import _ from 'lodash';

import { downloadFile, tableToCSV } from './utils/TableExportUtils';

import { formatDateTime, updateIntl } from '../lib/dateFormatter';

import {
    PDC_SEVERITY_HIGH,
    PDC_SEVERITY_HIGH_CLASS_NAME,
    PDC_SEVERITY_LOW_CLASS_NAME,
    PDC_SEVERITY_MEDIUM,
    PDC_SEVERITY_MEDIUM_CLASS_NAME,
} from '../constants';

import { getInspections } from '../selectors';
import {
    downloadProductActivationsAction,
    pdcFetchMoreInspectionsAction,
    pdcSelectedAction,
    showPdcSidebarAction,
} from '../actions/pdcActions';

import TableFilterBar from './TableFilterBar';
import PDFDownloadButtonComponent from './PDFDownloadButtonComponent';
import AnalyticsStore from './AnalyticsStore';
import getServiceLocation from '../lib/getServiceLocation';

const SortOrder = {
    ASC: 'ASC',
    DESC: 'DESC',
};

export const BASE_COLUMN_HEADER_ID = 'pdc.table.header';
export const NO_DATA_TEXT = <span>{'-'}</span>;
export const ADD_NEW_VEHICLE_LINK_TEXT = 'addNewVehicle';

export const getSeverityClass = (row) => {
    switch (row.severity) {
        case PDC_SEVERITY_HIGH:
            return PDC_SEVERITY_HIGH_CLASS_NAME;
        case PDC_SEVERITY_MEDIUM:
            return PDC_SEVERITY_MEDIUM_CLASS_NAME;
        default:
            return PDC_SEVERITY_LOW_CLASS_NAME;
    }
};

// eslint-disable-next-line react/display-name
export const getIssuesTranslationKey = (issueCount) => {
    // eslint-disable-next-line no-magic-numbers
    if (issueCount === 1) {
        return 'pdc.table.damage.count';
    } else if (issueCount) {
        return 'pdc.table.damages.count';
    }

    return 'pdc.table.damages.none';
};

// eslint-disable-next-line react/display-name
export const getFormatIssuesColumn = (formatMessage, cell) => {
    const issueCount = Number(cell);
    const severityClass = issueCount && 'warning';

    return (
        <span className={`text-color-${severityClass}`}>
            {formatMessage({ id: getIssuesTranslationKey(issueCount) }, { issueCount })}
        </span>
    );
};

// eslint-disable-next-line react/display-name
export const getFormatCriticalIssuesColumn = (formatMessage, cell) => {
    const issueCount = Number(cell);
    const severityClass = issueCount && 'danger text-bold';

    return (
        <span className={`text-color-${severityClass}`}>
            {severityClass ? <i className={'rioglyph rioglyph-warning-sign margin-right-5'} /> : ''}
            {formatMessage({ id: getIssuesTranslationKey(issueCount) }, { issueCount })}
        </span>
    );
};

// eslint-disable-next-line no-confusing-arrow,react/display-name
export const getFormatEmptyStringField = (cell) => (_.isEmpty(cell) ? NO_DATA_TEXT : <span>{cell}</span>);

// eslint-disable-next-line react/display-name
export const getFormatVehicleName = (formatMessage, vehicleName) => {
    if (vehicleName) {
        return <span>{vehicleName}</span>;
    }

    return (
        <a
            href={`${getServiceLocation('vehicleAdmin')}`}
            target={'_blank'}
            onClick={(event) => event.stopPropagation() /* avoid triggering other onClick handlers besides href */}
            rel="noreferrer"
        >
            {formatMessage({ id: ADD_NEW_VEHICLE_LINK_TEXT })}
        </a>
    );
};

// eslint-disable-next-line no-confusing-arrow
export const getFormatPdfField = (formatMessage, locale, pdfUrl, item) => (
    <AnalyticsStore data={{ eventCategory: 'table', pdc: item }}>
        <PDFDownloadButtonComponent formatMessage={formatMessage} locale={locale} pdfUrl={pdfUrl} />
    </AnalyticsStore>
);

// eslint-disable-next-line react/display-name
export const getFormatEmptyDateTimeField = (cell) => {
    const dateTimeCell = formatDateTime(cell);
    return _.isEmpty(dateTimeCell) ? NO_DATA_TEXT : <span>{dateTimeCell}</span>;
};

export const downloadCSV = (downloadNode, skipFirstColumn) => {
    downloadFile(
        btoa(unescape(encodeURIComponent(tableToCSV(downloadNode, skipFirstColumn)))),
        'text/csv',
        `${new Date().toUTCString()}.csv`,
    );
};

export const handleTableSelectionChange = (selectedElements, selectPdc, setSelectedElements, showSidebar) => {
    const [inspectionId] = selectedElements;
    showSidebar(!!(inspectionId && true));
    selectPdc(inspectionId);
    setSelectedElements(selectedElements);
};

const getSortedCopyByAttribute = (originalArray, attribute, sortOrder) => {
    // Create a shallow copy of the original array using the spread operator
    const sortedArray = [...originalArray];
    if (!originalArray || !attribute) {
        return sortedArray;
    }
    // Sort the copy based on the specified attribute
    sortedArray.sort((a, b) => {
        const attrFromA = a[attribute];
        const attrFromB = b[attribute];
        if (sortOrder === SortOrder.ASC) {
            return attrFromA.localeCompare(attrFromB);
        }
        return attrFromB.localeCompare(attrFromA);
    });
    return sortedArray;
};

// eslint-disable-next-line max-statements, max-lines-per-function
const PDCPageComponentWithoutInjection = (props) => {
    const {
        intl: { formatMessage, locale },
        pdc,
        onVehicleConditionFilterChange,
        filters,
        onFilterRemove,
        loadMore,
        selectPdc,
        pdcFetchMoreInspections,
        downloadProductActivations,
        showSidebar,
    } = props;

    const [sortByField, setSortByField] = useState(undefined);
    const [sortOrder, setSortOrder] = useState(SortOrder.DESC);
    const [selectedElements, setSelectedElements] = useState([]);
    useEffect(() => {
        downloadProductActivations();
    }, []);

    const selectedElement = (selectedElements || []).length === 1 ? selectedElements[0] : undefined;
    const handleRowClick = (selectedRowId) => {
        // if already selected, unselect
        const rowIdToSelect = selectedRowId === selectedElement ? undefined : selectedRowId;
        handleTableSelectionChange([rowIdToSelect], selectPdc, setSelectedElements, showSidebar);
    };
    const handleSortByClick = (fieldName) => {
        if (sortByField === fieldName) {
            setSortOrder(sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC);
        } else {
            setSortOrder(SortOrder.DESC);
            setSortByField(fieldName);
        }
    };

    updateIntl(locale, formatMessage);

    const sortedItems = getSortedCopyByAttribute(pdc.items, sortByField, sortOrder);

    return (
        <div className={'MainPage'}>
            <TableFilterBar
                downloadTableAsCSV={() => {
                    downloadCSV(document.getElementById('PDCTable'), false);
                }}
                filters={filters}
                onVehicleConditionFilterChange={onVehicleConditionFilterChange}
                onFilterRemove={onFilterRemove}
            />
            <div id="PDCTable" className="table-responsive">
                <table className="table table-hover table-head-filled table-bordered">
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id={`${BASE_COLUMN_HEADER_ID}.criticalIssueCount`} />
                            </th>
                            <th>
                                <FormattedMessage id={`${BASE_COLUMN_HEADER_ID}.issueCount`} />
                            </th>
                            <th className="sort-column" onClick={() => handleSortByClick('reportedBy')}>
                                <span>
                                    {sortByField === 'reportedBy' ? (
                                        <SortArrows direction={sortOrder} />
                                    ) : (
                                        <SortArrows />
                                    )}
                                    <FormattedMessage id={`${BASE_COLUMN_HEADER_ID}.reportedBy`} />
                                </span>
                            </th>
                            <th>
                                <FormattedMessage id={`${BASE_COLUMN_HEADER_ID}.vehicleNameInfo`} />
                            </th>
                            <th className="sort-column" onClick={() => handleSortByClick('vin')}>
                                <span>
                                    {sortByField === 'vin' ? <SortArrows direction={sortOrder} /> : <SortArrows />}
                                    <FormattedMessage id={`${BASE_COLUMN_HEADER_ID}.vin`} />
                                </span>
                            </th>
                            <th>
                                <FormattedMessage id={`${BASE_COLUMN_HEADER_ID}.trailerNameInfo`} />
                            </th>
                            <th>
                                <FormattedMessage id={`${BASE_COLUMN_HEADER_ID}.modificationTimestamp`} />
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {sortedItems.map((item) => (
                            <tr
                                key={`tr-key-${item.id}`}
                                className={`${selectedElement === item.id ? 'active' : ''} ${getSeverityClass(item)}`}
                                onClick={() => handleRowClick(item.id)}
                            >
                                <td>{getFormatCriticalIssuesColumn(formatMessage, item.criticalIssueCount)}</td>
                                <td>{getFormatIssuesColumn(formatMessage, item.issueCount)}</td>
                                <td>{getFormatEmptyStringField(item.reportedBy)}</td>
                                <td>{getFormatVehicleName(formatMessage, item.vehicleNameInfo)}</td>
                                <td>{getFormatEmptyStringField(item.vin)}</td>
                                <td>{getFormatEmptyStringField(item.trailerNameInfo)}</td>
                                <td>{getFormatEmptyDateTimeField(item.modificationTimestamp)}</td>
                                <td>{getFormatPdfField(formatMessage, locale, item.pdfLink, item)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {loadMore && (
                <div id={'loadMore'} className={'text-center'}>
                    <button
                        className={'btn btn-default margin-20'}
                        onClick={pdcFetchMoreInspections.bind(null, loadMore)}
                    >
                        {formatMessage({ id: 'table.loadMore' })}
                    </button>
                </div>
            )}
        </div>
    );
};

PDCPageComponentWithoutInjection.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
        locale: PropTypes.any,
    }).isRequired,
    pdc: PropTypes.shape(
        PropTypes.objectOf({
            items: PropTypes.array.isRequired,
            links: PropTypes.object.isRequired,
        }),
    ).isRequired,
    onVehicleConditionFilterChange: PropTypes.func.isRequired,
    filters: PropTypes.array.isRequired,
    onFilterRemove: PropTypes.func.isRequired,
    downloadProductActivations: PropTypes.func.isRequired,
    loadMore: PropTypes.any,
    selectPdc: PropTypes.any,
    pdcFetchMoreInspections: PropTypes.any,
    showSidebar: PropTypes.any,
};

export const mapStateToProps = (state) => ({
    loadMore: _.get(getInspections(state), 'links.next.href'),
});

export const mapDispatchToProps = (dispatch) => ({
    selectPdc: (id) => dispatch(pdcSelectedAction(id)),
    pdcFetchMoreInspections: (url) => dispatch(pdcFetchMoreInspectionsAction(url)),
    downloadProductActivations: () => dispatch(downloadProductActivationsAction()),
    showSidebar: (display) => dispatch(showPdcSidebarAction(display)),
});

export { PDCPageComponentWithoutInjection };
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(PDCPageComponentWithoutInjection));
